import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {

  transform(value: string | undefined): unknown {
    if (value) {
      const date: moment.MomentInput = value;
      return moment.utc(date, true).fromNow();
    } else {
      return 'Not available';
    }
  }


}
